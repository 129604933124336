import React, { useMemo } from 'react';
import Header from '@components/molecules/Header'
import {useIntl} from 'gatsby-plugin-react-intl';
import {isBrowser} from '../utils/ssr';
import {Helmet} from 'react-helmet';

const Page404 = () => {
    const intl = useIntl();

    const twitterCardImageLink = {
        ru: 'https://app.scope.gg/static/meta-images/ru/landing.jpg',
        en: 'https://app.scope.gg/static/meta-images/eng/landing.jpg',
        fr: 'https://app.scope.gg/static/meta-images/eng/landing.jpg',
        es: 'https://app.scope.gg/static/meta-images/eng/landing.jpg',
        pt: 'https://app.scope.gg/static/meta-images/eng/landing.jpg',
    };
    const canonicalLinks = useMemo(() => {
        if (isBrowser) {
            const location = window.location.origin;

            return {
                ru: `${location}/ru`,
                en: `${location}`,
                fr: `${location}/fr`,
                es: `${location}/es`,
                pt: `${location}/pt`,
            };
        }

        return {
            ru: 'https://scope.gg/ru',
            en: 'https://scope.gg',
            fr: 'https://scope.gg/fr',
            es: 'https://scope.gg/es',
            pt: 'https://scope.gg/pt',
        };
    }, []);

    const hreflangLinks = useMemo(() => {
        if (isBrowser) {
            const location = window.location.origin;

            return {
                ru: location,
                en: `${location}/ru/`,
                fr: `${location}/fr/`,
                es: `${location}/es/`,
                pt: `${location}/pt/`
            };
        }

        return {
            ru: 'https://scope.gg/',
            en: 'https://scope.gg/ru/',
            fr: 'https://scope.gg/fr/',
            es: 'https://scope.gg/es/',
            pt: 'https://scope.gg/pt/',
        };
    }, []);

    return (
        <>
            <Helmet>
                {process.env.GATSBY_ACTIVE_ENV !== 'production' ? <meta name="robots" content="noindex, nofollow" /> : null}
                <meta charSet="utf-8"/>
                <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"/>
                <meta name="facebook-domain-verification" content="bpvvsvqocb18kyodlluk1feteplh4z"/>
                <meta property="fb:app_id" content="257953674358265"/>
                <meta name="format-detection" content="telephone=no"/>
                <meta httpEquiv="x-dns-prefetch-control" content="on"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <title>{intl.formatMessage({id: 'seo.index.title'})}</title>
                <meta name="description" content={intl.formatMessage({id: 'seo.index.desc'})}/>
                <meta property="og:url" content="https://scope.gg"/>
                <meta property="og:title" content={intl.formatMessage({id: 'seo.index.og|title'})}/>
                <meta property="og:description" content={intl.formatMessage({id: 'seo.index.og|desc'})}/>
                <meta name="twitter:site" content="@scope_gg"/>
                <meta name="twitter:title" content={intl.formatMessage({id: 'seo.index.twitter|title'})}/>
                <meta name="twitter:description" content={intl.formatMessage({id: 'seo.index.twitter|desc'})}/>
                <meta name="twitter:image" content={twitterCardImageLink[intl.locale]}/>
                <link rel="canonical" href={canonicalLinks[intl.locale]}/>
                <link rel="icon" href="/favicon.ico"/>
                {Object.keys(hreflangLinks).map((lang) => (
                    <link key={lang} rel="alternate" hrefLang={hreflangLinks[lang]}/>
                ))}
            </Helmet>
            <div className="not-found-layout">
                <div className="top-map">
                    <Header />
                </div>
                <div className="not-found__container">
                    <h1 className="not-found__title">{intl.formatMessage({ id: 'pages.404.title' })}</h1>
                    <span className="not-found__untitle">
                       {intl.formatMessage({ id: 'pages.404.subtitle' })}
                    </span>
                </div>
            </div>
        </>
    )
}

export default Page404;
